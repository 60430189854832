<template lang="pug">
  Auth(@loggedIn="onLoggedIn"
    :redirectWithLoginFailed="false")
    Header(:content="headerContent")
    div.wrap-invitation-lp
      ModuleInvitationLP(v-if="meeting && inviter"
        :meeting="meeting" :inviter="inviter")
</template>

<style lang="scss" scoped>
.wrap-invitation-lp {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header.vue'
import ModuleInvitationLP from '@/components/module/ModuleInvitationLP'

export default {
  components: {
    Auth,
    Header,
    ModuleInvitationLP
  },
  data() {
    return {
      headerContent: {
        title: '日程調整を開始'
      },
      meeting: null,
      inviter: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created() {
    this.meeting = await database.meetingCollection().findById(this.$route.params.meetingId)
    if (this.meeting.status === 'adjusted' || this.meeting.status === 'failed')
      this.$router.push(`/meeting/${this.$route.params.meetingId}`)

    this.inviter = await database.userCollection().findById(this.meeting.createdBy)
  },
  methods: {
    onLoggedIn() {}
  }
}
</script>
