<template lang="pug">
  div.wrap-module-invitation-lp
    div(v-if="inviter").wrap-inviter.py40
      div.f.fc.mb20
        v-avatar(size="120px")
          v-img(:src="inviter.iconURL")
      span.text-center.block.fz14.mb4 「{{meeting.title}}」に
      span.text-center.block.fz14.mb4 {{inviter.name}}がミーティングに招待しました。
      span.text-center.block.fz14 自分の名前を選択し、日程調整を開始しましょう！
    div.invitation-lp-content
      div(v-for="attendee in notResponsedAttendee"
        @click="$router.push(`/invitation/recommend-google-auth/${$route.params.meetingId}/${attendee.attendeeIndex}`)").wrap-list.not-responsed.py8
        div.list-content.f.fm.flex-between
          div.f.fm
            span.name.mr8 {{attendee.name}}
            span.status-label.fz10 日程未送信
          v-icon mdi-chevron-right
      div(v-for="attendee in responsedAttendee").wrap-list.responsed.py8
        div.list-content.f.fm
          span.name.mr8 {{attendee.name}}
          span.status-label.fz10 日程送信済
      //- div(@click="$router.push(`/invitation/recommend-google-auth/${$route.params.meetingId}`)").wrap-list.add-attendee.py8
        div.list-content.f.fm
          v-icon(color="#4285f4").mr4 mdi-plus
          span.fz14 新規メンバーとして参加する
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-invitation-lp {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .wrap-inviter {
    width: $base_width_percent;
    margin: 0 auto;
  }
  .invitation-lp-content {
    .wrap-list {
      border-bottom: $basic_border;
      &:first-child {
        border-top: $basic_border;
      }
      .list-content {
        width: $base_width_percent;
        max-width: $base_max_width_px;
        margin: 0 auto;
      }
      &.not-responsed {
        .status-label {
          color: $dangerous_color;
        }
      }
      &.responsed {
        .name {
          color: $secondary_text_color;
        }
        .status-label {
          color: $active_color;
        }
      }
      &.add-attendee {
        color: $active_color;
      }
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {},
  props: {
    meeting: {
      type: Object,
      required: true
    },
    inviter: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    notResponsedAttendee() {
      return this.meeting.attendee
        .map((e, i) => {
          return { ...e, attendeeIndex: i }
        })
        .filter((e) => !e.responsed)
    },
    responsedAttendee() {
      return this.meeting.attendee
        .map((e, i) => {
          return { ...e, attendeeIndex: i }
        })
        .filter((e) => e.responsed)
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
