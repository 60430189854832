import { render, staticRenderFns } from "./InvitationLP.vue?vue&type=template&id=a2139714&scoped=true&lang=pug&"
import script from "./InvitationLP.vue?vue&type=script&lang=js&"
export * from "./InvitationLP.vue?vue&type=script&lang=js&"
import style0 from "./InvitationLP.vue?vue&type=style&index=0&id=a2139714&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2139714",
  null
  
)

export default component.exports